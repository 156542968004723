import React from 'react'
import styled from 'styled-components'
import * as ds from '../uiLibrary'
import Axios from 'axios'
import isPast from 'date-fns/is_past'
import * as qs from 'query-string'
import isLength from 'validator/lib/isLength';
import tonitLogoDark from '../images/tonit-logo-dark.png'
import tonitKey from '../images/tonit-key.png'

const MIN_PASSWORD_LENGTH = 6;

const StyledLogo = styled.img`
  max-width: 240px;
  padding: 64px 0 32px 0;
  display: block;
  margin: auto;
`
const StyledKey = styled.img`
  max-width: 64px;
  display: block;
  margin: auto;
`
const Error = styled(ds.P)`
  font-size: ${ds.typeScale.t4};
  color: #FF3B30; 
`
const Success = styled(ds.P)`
  color: #4CD964; 
`
class ResetPassword extends React.Component {
  constructor(props) {
    super(props)

    const {
      location
    } = this.props

    // store the params
    if (location.search) {
      const parsedParams = qs.parse(location.search)
      this.token = parsedParams.token
      this.expiryDate = parsedParams.expiryDate
    }

    this.state = {
      p: '',
      p2: '',
      error: '',
      submitting: false,
    }
  }

  render() {
    return (
      <div>
        <ds.Background bg={ds.colors.bg.lightGray} height={"100vh"}>
          <StyledLogo src={tonitLogoDark} />
          <ds.Card>
            <form id="reset-password-form">
              <StyledKey src={tonitKey} />
              <ds.H2 textAlign={"center"} color={ds.colors.black} mb={ds.spacing.s0}>Reset your password</ds.H2>
              <ds.P color={ds.colors.black} textAlign={"center"} mb={ds.spacing.s4}>Enter your new password below.</ds.P>
              <ds.Label>New password</ds.Label>
              <ds.StyledInput
                my={ds.spacing.s3}
                color={ds.colors.black}
                borderBottom="1px solid lightgrey"
                borderRadius="0"
                placeholder="Enter a new password"
                type='password'
                onChange={e => this.setState({
                  error: '',
                  p: e.target.value,
                })}
              />
              <br />
              <ds.Label>Retype password</ds.Label>
              <ds.StyledInput
                my={ds.spacing.s3}
                color={ds.colors.black}
                borderBottom="1px solid lightgrey"
                borderRadius="0"
                placeholder="Retype your new password"
                type='password'
                onChange={e => this.setState({
                  error: '',
                  p2: e.target.value,
                })}
              />
              <ds.Button
                onClick={this._handleSubmit}
                small
                disabled={this.state.submitting}
                className={this.state.submitting ? "disabled" : ""} >
                {this.state.submitting ? "Submitting..." : "Done"}
              </ds.Button>
              <Success>
                {this.state.success}
              </Success>
              <Error>
                {this.state.error}
              </Error>
            </form>
          </ds.Card>
        </ds.Background>
      </div>
    )
  }

  _handleSubmit = e => {
    e.preventDefault();
    if (!this.state.p || !this.state.p2) {
      return this.setState({
        error: "Please type your new password in both fields",
      });
    }

    if (!isLength(this.state.p, { min: MIN_PASSWORD_LENGTH })) {
      return this.setState({
        error: `The password is not long enough (Min ${MIN_PASSWORD_LENGTH} characters)`,
      });
    }

    if (this.state.p !== this.state.p2) {
      return this.setState({
        error: `Those passwords don't match`,
      });
    }

    if (isPast(this.expiryDate)) {
      return this.setState({
        error: `This password reset link has expired. Reset your password again to continue`
      });
    }

    this.setState({
      submitting: true,
    });

    return Axios({
      method: 'put',
      url: `${process.env.TONIT_API_URL}/users/resetPassword`,
      // url: `http://192.168.0.105:3000/api/v1/users/resetPassword`,
      data: {
        token: this.token,
        expiryDate: this.expiryDate,
        password: this.state.p,
      },
    }).then(response => this.setState({
      error: null,
      success: response.data.message,
      submitting: false,
    })).catch(e => this.setState({
      success: null,
      error: `Couldn't reset your password. Please request a new password reset from the app.`,
      submitting: false,
    }));
  }
}

export default ResetPassword;